import { Auction, Careseeker } from "core/types";

export const composeAnd =
  (
    ...functions: Array<
      (props: { careseeker?: Careseeker; formInputValue: Auction }) => boolean
    >
  ) =>
  (props: { careseeker?: Careseeker; formInputValue: Auction }) =>
    functions.every((f) => f(props));

export const blacklist =
  (key: string) =>
  ({ careseeker }: { careseeker?: Careseeker }) => {
    if (!careseeker?.config?.blacklisted_assessment_fields) return true;
    return !careseeker.config.blacklisted_assessment_fields.includes(key);
  };
